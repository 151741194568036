
#notifications {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  width: auto;
  height: 0;
  overflow: visible;
}

#snackbar {
  position: fixed;
  bottom: 0;
  inset-inline-start: 0;
  height: auto;
  width: auto;
  max-width: 700px;
  overflow: visible;
  padding-inline-start: clamp(
    10px,
    var(--margin-m),
    var(--margin-m)
  ); // support weUI with default fallback fallback

  @media screen and (max-width: 800px) {
    max-width: 90vw;
  }
}
