
@use 'sass:math';

.test-a {
    margin-bottom : 70px;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration        : 0.3s;
    transition-property        : height, opacity;
    transition-timing-function : ease;
    overflow                   : hidden;
}

.fade-enter,
.fade-leave-active {
    opacity : 0
}

.calibration {
    margin : auto;
}

.answer-display {
    max-width  : 300px;
    margin     : 0 auto;
}

.user-input-field, .play-indicator-field {
    max-width  : 300px;
    margin     : 0 auto;
    background : transparent;
    font-size  : 30px;
    text-align : center;
    direction  : ltr;

    .input-content {
        flex  : 0 0 20%;
        // padding: 0 20px;
        color : var(--c-inverse); //$global-secondary-background; //#######
    }

    .digit-feedback {
        top    : 100%;
        height : 5px;
        width  : 100%;

        &__inner {
            height : 5px;
        }
    }
}

.play-indicator-field {
    display         : flex;
    align-items     : center;
    justify-content : center;

}

.indicator-slot {
    display : flex;
    justify-content: center;
    height  : 36px;
    width   : 20%;
    flex: 0 0 20%;

    .uk-icon {
        display         : flex;
        align-items     : center;
        justify-content : center;
        height          : 100%;
        width           : 100%;
        margin          : 0;
    }
}

$input-char-size    : 65px;
$input-char-margin  : 12px;
$ui-container-width : $input-char-size*3 +  $input-char-margin*6;

.message-container {
    overflow : hidden;
}

.message-area {
    position   : relative;
    right      : 0;
    top        : 0;
    width      : 2*$ui-container-width;
    max-width  : 2*$ui-container-width;
    height     : auto;
    padding    : 10px 15px;
    box-sizing : border-box;
}

.test-a-input-ui {
    position : relative;
}

.digit-input-ui {
    margin          : 20px auto 10px;
    display         : flex;
    justify-content : center;
    flex-wrap       : wrap;
    width           : $ui-container-width;

    // rtl numpad should look the same as ltr
    [dir=rtl] & {
        flex-direction : row-reverse;
    }

    .input-ui-char {
        display         : flex;
        align-items     : center;
        justify-content : center;
        height          : $input-char-size;
        width           : $input-char-size;
        margin          : math.div($input-char-margin, 2) $input-char-margin;
        font-size       : math.div($input-char-size, 2.1); // $input-char-size/2.1;
        font-weight     : bold;
        cursor          : pointer;
        transition      : all 150ms;

        &:hover {
            opacity : 0.9;
        }

        &:active {
            opacity : 0.7;
        }
    }

    .user-input-reset, .user-input-submit {
        .uk-icon {

        }
    }

    .user-input-reset {
        background : var(--c-error); //$global-danger-background;//#######
        order      : 89;
    }

    .user-input-submit {
        order : 91;
    }
}

.html-log-list {
    direction  : ltr;
    text-align : left;

    li {
        margin-bottom : 20px;
    }
}

.html-log-data {
    display      : inline-block;
    margin-right : 10px;
    width        : 150px;
}

.button-export {
    min-width : 150px;
}

.noise-bar {
    position    : fixed;
    top         : 0;
    right       : 0;
    width       : 100%;
    background  : red;
    color       : white;
    height      : 35px;
    z-index     : 20;
    font-size   : 20px;
    text-align  : center;
    font-weight : bold;
}
