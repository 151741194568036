

.logo-container {
    $logo-size: 75px;
    height:  $logo-size;
    width:  $logo-size;
    flex: 0 0 $logo-size;
    margin: 0 auto var(--margin-xl) auto;

    @media screen and (max-width: 767px) {
        $logo-size: 50px;
        height:  $logo-size;
        width:  $logo-size;
        flex: 0 0 $logo-size;
        margin-bottom: var(--margin-m);
    }
    img {
        height: 100%;
        width: 100%;
    }
}
